html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.link {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  color: #323232;
  font-family: 'Unbounded', sans-serif;
}
