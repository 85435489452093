.section_trands {
  padding: 53px 0 40px;
}

.wrapper_title_trends {
  margin-bottom: 20px;
}

.title_trends {
  margin-left: 10px;
  color: #303030;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36.484px;
}

.trands_description {
  color: #303030;

  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;

  &_boold {
    font-weight: 500;
  }
}

.trends_list {
  display: none;
}

@include for-size(tablet) {
  .trands_description {
    margin-bottom: 80px;
  }

  .wrapper_title_trends {
    justify-content: center;
    margin-bottom: 16px;
  }

  .wrapper_title_trends img {
    width: 62px;
  }

  .title_trends {
    margin-left: 20px;

    font-size: 50px;
    line-height: 70px;
  }

  .trands_description {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
  }

  .trends_list {
    display: flex;
    width: 100%;

    &_item {
      display: flex;
      align-items: start;
      justify-content: space-around;
      flex-direction: column;
      text-align: start;
      padding: 20px 1px 27px 20px;
      width: calc(100% / 5);
      height: 278px;
      color: #fff;
      background: #303030;
      border-radius: 20px;

      transition: 300ms;
      cursor: pointer;

      &:hover {
        background: linear-gradient(
          166deg,
          #cfd1ff 5.71%,
          #989bff 44.9%,
          #777bff 71.07%,
          #686bef 97.29%
        );
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      &_title {
        width: 200px;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
      }

      &_description {
        max-width: 254px;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 15px;
      }
    }
  }
}
