.swipe_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-left: 3px;
  width: 50px;
  height: 50px;
  background: linear-gradient(
    180deg,
    #a4a7ff 0%,
    #7175ff 27.94%,
    #484dfd 62.42%,
    #383de7 108.78%
  );

  border-radius: 50%;
}

@include for-size(tablet) {
  .swipe_icon {
    display: none;
  }
}
