.section_marketing {
  padding: 80px 0 53px;
}

.wrapper_icon_marketing {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.section_baner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  padding: 70px 5px 73px;
  max-height: 212px;
  text-align: center;
  border-radius: 30px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;

  background-image: url('../images/marketing/baner-bg-mobile.webp'),
    linear-gradient(
      166deg,
      #cfd1ff 5.71%,
      #989bff 44.9%,
      #777bff 71.07%,
      #686bef 97.29%
    );

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.marketing_description {
  margin-bottom: 40px;
  text-align: center;
  color: #303030;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;

  &_boold {
    font-weight: 500;
  }
}

.marketing_description_desk {
  display: none;
}

.laptop_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 80px;
  max-width: 428px;
  height: 236px;
  background: url('../images/marketing/laptop.webp');
  background-size: cover;
  background-position: center;
}

.marketing_card {
  padding: 100px 0 0;
  height: 720px;
  border-radius: 60px;

  background: url('../images/marketing/mask-mobile.webp'),
    linear-gradient(107deg, #fff 5.17%, #fafafa 11.57%, #f3f3f3 61.45%);
  background-size: cover;
  background-repeat: no-repeat;

  &_box {
    padding: 0 30px 0 15px;
  }

  &_title {
    margin-bottom: 20px;
    color: #303030;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }

  &_description {
    margin-bottom: 40px;
    color: #303030;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  }
}

.button.button_marketing {
  justify-content: center;
}

@include for-size(tablet) {
  .section_baner {
    margin: 0 auto 80px;
    padding: 0 120px;
    max-width: 950px;
    height: 131px;
    background-image: url('../images/marketing/baner-bg-desktop.webp'),
      linear-gradient(
        166deg,
        #cfd1ff 5.71%,
        #989bff 44.9%,
        #777bff 71.07%,
        #686bef 97.29%
      );
  }

  .marketing_description {
    display: none;
  }

  .marketing_description_desk {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 80px;
    color: #303030;
    font-size: 30px;
    font-weight: 300;
    line-height: 40px;

    &_bold {
      font-weight: 500;
    }
  }

  .laptop_box {
    margin: 0 auto 80px;
    max-width: 854px;
    height: 471px;
    background: url('../images/marketing/laptop-desktop.webp');
    background-size: contain;
    background-position: center;
  }

  .marketing_card {
    max-height: 560px;

    padding: 150px 0 115px 32px;

    background: url('../images/marketing/mask-groop1-desk.webp')
        calc(100% - -50px) 0,
      url('../images/marketing/mask-groop2-desk.webp') right,
      linear-gradient(107deg, #fff 5.17%, #fafafa 11.57%, #f3f3f3 61.45%);
    background-size: contain;
    background-repeat: no-repeat;

    &_box {
      padding: 0;
    }

    &_title {
      max-width: 690px;
    }

    &_description {
    }
  }
}

@include for-size(desktop) {
  .section_marketing {
    padding: 80px 0 77px;
  }

  .marketing_card {
    height: 560px;

    // padding: 150px 0 115px 118px;
    background-size: contain;

    &_title {
      max-width: 690px;
      font-size: 50px;
      line-height: 55px;
    }

    &_description {
      font-size: 20px;
      line-height: 25px;
    }
  }
}

@include for-size(onlyDesktop) {
  .marketing_card {
    padding: 150px 0 115px 118px;
  }
}
