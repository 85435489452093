.menu {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  padding: 28px 30px 161px;

  background: #202020;

  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);

  overflow-y: scroll auto;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 162px;
    width: 100%;
  }
}

.menu_mail_link {
  color: $primary-color;
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
  margin: 80px 0 30px;
}

.menu_list {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 40px;

  &_item {
    width: 100%;
  }

  &_link {
    color: $primary-color;

    font-size: 30px;

    font-weight: 300;
    line-height: 100px;
  }
}

.menu.is-hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.close_menu_btn {
  margin: 0 auto;

  background: transparent;

  border: none;
  cursor: pointer;
}
