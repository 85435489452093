.to_top_wrapper {
}

.to_top_btn {
  position: absolute;
  bottom: 153px;
  right: 30px;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  transition: 300ms;

  &:hover {
    filter: drop-shadow(0px 20px 50px rgba(138, 142, 255, 0.4));
  }
}
