.section_subscribe {
  padding: 80px 0 80px;
}

.container_subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subscribe_title {
  color: #303030;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.subscribe_description {
  margin-bottom: 40px;
  color: #303030;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
}

.button.button_subscribe {
  justify-content: center;
  margin-bottom: 82px;
}

.subscribe_card {
  padding: 100px 15px 148px;
  border-radius: 60px;
  background: url('../images/subscribe/mask-mobile.webp'),
    url('../images/subscribe/rectangle-mobile.webp'),
    linear-gradient(107deg, #fff 5.17%, #fafafa 11.57%, #f3f3f3 61.45%);

  background-size: cover;
  background-repeat: no-repeat;

  &_title {
    margin-bottom: 20px;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }

  &_description {
    margin-bottom: 37px;
    color: #303030;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  }
}

.subscribe_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 15px;
  border-radius: 30px;
  background: #fff;
}

.subscribe_form_title {
  margin-bottom: 16px;
  padding: 0 35px;
  color: #303030;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.subscribe_form_social_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
  width: 100%;

  &_item {
    display: flex;
    align-items: center;

    padding: 8px;
    color: #282828;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 21.326px;
    border: 1.066px solid transparent;
  }

  &_item:active {
    border-radius: 42.652px;
    border: 1.066px solid #a4a7ff;
  }

  &_item:focus {
    border-radius: 42.652px;
    border: 1.066px solid #a4a7ff;
  }

  .subscribe_form_icon {
    margin-right: 5px;
    border-radius: 50%;
  }

  .subscribe_form_icon.viber {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #7460f3;
  }
}

.subscribe_option_box {
  width: 100%;
}

.subscribe_input {
  width: 100%;
  height: 65px;
  padding-left: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1.5px solid var(--Linear, #f3f3f3);
  background: #f6f6f6;
}

.subscribe_input::placeholder {
  color: #6b6b6b;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.custom-checkbox input[type='checkbox'] {
  display: none;
}

.custom-checkbox label {
  position: relative;
  padding-left: 19px;
  cursor: pointer;
}

.custom-checkbox label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 7px;
  width: 15px;
  height: 15px;
  border: none;
  border-radius: 2px;
  background: var(
    --Linear,
    linear-gradient(
      166deg,
      #cfd1ff 5.71%,
      #989bff 44.9%,
      #777bff 71.07%,
      #686bef 97.29%
    )
  );
}

.custom-checkbox input[type='checkbox']:checked + label::after {
  content: '';
  position: absolute;
  background: url('../images/icons/checkmark.svg') center center no-repeat;
  width: 15px;
  height: 15px;
  left: 0;
  top: 7px;
}

.checkbox_lable {
  color: #282828;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
}

.button.button_form_subscribe {
  justify-content: center;
  margin: 0 auto;
}

@include for-size(tablet) {
  .section_subscribe {
    padding: 50px 0 130px;
  }

  .subscribe_title {
    font-size: 30px;
    line-height: 40px;
  }

  .subscribe_description {
    max-width: 948px;
    font-size: 30px;
    line-height: 40px;
  }

  .button.button_subscribe {
    margin-bottom: 130px;
  }

  .subscribe_card {
    display: flex;
    justify-content: space-between;
    padding: 97px 30px 90px 17px;
    height: 560px;
    width: 100%;

    background-image: url('../images/subscribe/mask-desk.webp'),
      url('../images/subscribe/rectangle-desk.webp'),
      linear-gradient(107deg, #fff 5.17%, #fafafa 11.57%, #f3f3f3 61.45%);

    background-size: cover;

    &_title {
      padding-right: 10px;
      font-size: 50px;
      line-height: 55px;
    }

    &_description {
      padding-right: 10px;
      font-size: 20px;
      line-height: 25px;
    }
  }

  .subscribe_form {
    width: 100%;
    max-width: 688px;
    padding: 61px 30px 30px;
  }

  .subscribe_form_title {
    margin-bottom: 39px;
    font-size: 25px;
    line-height: 30px;
  }

  .subscribe_form_social_list {
    justify-content: center;

    &_item {
      padding: 8px 20px;
      font-size: 15px;
      line-height: 30px;
    }

    .subscribe_form_icon {
      width: 33px;
      margin-right: 10px;
      border-radius: 50%;
    }

    .subscribe_form_icon.viber {
      width: 33px;
      height: 33px;
      margin-right: 10px;

      & img {
        width: 17px;
        height: 17px;
      }
    }
  }

  .subscribe_option_box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    & div {
      width: 100%;
    }
  }

  .button.button_form_subscribe {
    margin: 0 0 0 10px;
    height: 65px;
  }
}

@include for-size(desktop) {
  .subscribe_card {
    padding: 97px 50px 106px 118px;

    .subscribe_card_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
