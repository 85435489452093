.section_about {
  padding: 40px 0 80px;
}

.wrapper {
  display: flex;
  align-items: center;
}

.image_about_wrapper {
  margin-bottom: 40px;
  padding: 0 17px;
  height: 394px;
}

.content_about {
  padding: 0 30px;
}

.image_about {
  width: 100%;
  height: 100%;
  background-image: url('../images/about/bg.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.wrapper_title {
  margin-bottom: 20px;
}

.title_about {
  margin-left: 10px;
  color: #303030;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
}

.description_about {
  margin-bottom: 30px;

  color: #6b6b6b;

  font-size: 15px;
  font-style: normal;
  font-weight: 250;
  line-height: 20px;

  &_last {
    margin-bottom: 40px;
  }
}

.button.button_about {
  justify-content: center;
}

.btn_icon {
  margin-left: 10px;
}

.link_case {
  display: flex;
  align-items: center;
  margin-left: 18px;

  color: #303030;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 27.882px;
}

@include for-size(tablet) {
  .container_abaut {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .image_about_wrapper {
    width: 100%;
    margin: 0 50px 0 0;
    padding: 0;
  }
}

@include for-size(desktop) {
  .section_about {
    display: flex;
    padding: 176px 0 130px;
  }

  .image_about_wrapper {
    margin: 0 179px 0 0;
  }

  .container_abaut {
    padding: 0 140px 0 194px;
  }

  .wrapper_title img {
    width: 62px;
  }

  .title_about {
    font-size: 50px;

    line-height: 70px;
  }

  .description_about {
    font-size: 20px;
    line-height: 25px;

    &_last {
    }
  }

  .link_case {
    margin-left: 30px;
  }
}
