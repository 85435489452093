.separator {
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 0.03) 20%,
    rgba(255, 255, 255, 0.365) 50%,
    rgba(255, 255, 255, 0.03) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
