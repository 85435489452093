@mixin for-size($range) {
  $mobile: 428px;
  $tablet: 768px;
  $desktop: 1600px;
  $onlyDesktop: 1920px;

  @if $range == mobile-only {
    @media screen and (max-width: #{$mobile - 1}) {
      @content;
    }
  } @else if $range == mobile {
    @media screen and (min-width: $mobile) {
      @content;
    }
  } @else if $range == tablet-only {
    @media screen  and (min-width: $tablet) and (max-width: #{$desktop - 1}) {
      @content;
    }
  } @else if $range == tablet {
    @media screen and (min-width: $tablet) {
      @content;
    }
  } @else if $range == desktop {
    @media screen and (min-width: $desktop) {
      @content;
    }
  } @else if $range == onlyDesktop {
    @media screen and (min-width: $onlyDesktop) {
      @content;
    }
  }
}
