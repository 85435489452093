.section_footer {
  position: relative;
  padding: 80px 0 20px 0;
  background: #303030;
}

.section_footer .social_list.desktop {
  display: none;
}

.footer {
  &_logo {
    margin-bottom: 80px;
  }

  &_list {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    // &_item {
    //   width: 100%;
    // }

    &_item:not(:last-child) {
      margin-bottom: 30px;
    }

    &_link {
      color: $primary-color;

      font-size: 20px;
      font-weight: 300;
    }
  }

  &_phone {
    display: inline-block;
    margin-bottom: 20px;
    color: $primary-color;

    font-size: 20px;

    font-weight: 300;
    line-height: normal;

    &_boold {
      color: $primary-color;
      font-weight: 500;
    }
  }

  &_address {
    margin-bottom: 20px;

    color: $primary-color;

    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
  }

  &_mail_link {
    display: inline-block;
    padding-bottom: 40px;
    color: $primary-color;

    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
  }
}

.social_list {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.social_item {
  background: #fff;
  border-radius: 50%;
  &:not(:last-child) {
    margin-right: 10px;
  }

  &_link {
    color: $primary-color;
  }
}

.copirite {
  padding-top: 20px;
  text-align: center;
  color: $primary-color;

  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
}

@include for-size(desktop) {
  .section_footer {
    position: relative;
    padding: 73px 0 20px 0;
  }

  .footer_logo {
    margin-right: 60px;
  }

  .footer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 140px;

    &_logo {
      position: relative;
      top: -20px;
      margin-bottom: 0;
      height: 65px;
    }

    &_mail_link {
      display: flex;
      align-items: center;
      padding: 0;
      height: 36px;

      min-width: 250px;
    }
  }

  .section_footer .social_list.desktop {
    display: flex;
  }

  .section_footer .social_list.mobile {
    display: none;
  }

  .footer_list {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;

    &_item:not(:last-child) {
      margin-bottom: 0;
      margin-right: 50px;
    }
  }

  .footer_list_item:nth-child(3) {
    display: inline-block;
    min-width: 115px;
  }

  .social_item {
    width: 36px;
  }
}
