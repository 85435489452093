.section_improve {
  padding: 42px 0 0 0;
}

.improve_box {
  position: relative;
  margin-bottom: 80px;
  padding: 100px 40px 522px 15px;
  border-radius: 60px;
  background-image: url('../images/improve/phone.webp'),
    linear-gradient(107deg, #303030 5.17%, #212121 61.45%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.title_improve_card {
  margin-bottom: 20px;
  color: $primary-color;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.title_improve_card_color {
  margin-bottom: 40px;

  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;

  background: var(
    --Linear,
    linear-gradient(116deg, #cfd1ff 51.78%, #989bff 84.71%, #777bff 109.74%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.description_improve_card {
  margin-bottom: 40px;
  color: $primary-color;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.improve_card_wrapper_btn {
  display: flex;
  align-items: center;
}

.button.button_improve {
  justify-content: center;
  margin-right: 20px;
}

.wrapper_improve {
  margin-bottom: 20px;
}

.title_improve {
  margin-left: 10px;
  padding-right: 100px;
  color: #303030;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.description_improve {
  margin-bottom: 40px;
  color: #303030;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;

  &_boold {
    font-weight: 500;
  }
}

.improve_list {
  display: none;
}
//////////////////////////////////////////// slider ////////////////////////////////////////////
.slider_image_wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  height: 81px;
}

.slider_item_title {
  margin-bottom: 15px;
  color: #303030;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;

  &_description {
    color: #6b6b6b;

    font-size: 15px;
    font-style: normal;
    font-weight: 250;
    line-height: 20px;
  }
}
//////////////////////////////////////////// slider ////////////////////////////////////////////

@include for-size(tablet) {
  .improve_box {
    margin-bottom: 131px;
    height: 560px;
    padding: 100px 40px 80px 17px;
    background-image: url('../images/improve/phone_desktop.webp'),
      url('../images/improve/union-map.webp'),
      linear-gradient(107deg, #303030 5.17%, #212121 61.45%);
    background-position: bottom right, right;
    background-size: contain;
  }

  .wrapper_improve {
    justify-content: center;
  }

  .wrapper_improve img {
    width: 62px;
  }

  .title_improve {
    margin-left: 20px;

    font-size: 50px;
    line-height: 70px;
  }

  .description_improve {
    margin-bottom: 50px;
    text-align: center;
    padding-right: 90px;
    font-size: 20px;
    line-height: 30px;

    &_boold {
      font-weight: 500;
    }
  }

  .title_improve_card {
    max-width: 630px;
    font-size: 50px;
    line-height: 55px;

    &_color {
      font-size: 50px;
      line-height: 55px;
    }
  }

  .description_improve_card {
    max-width: 630px;

    font-size: 20px;
    line-height: 25px;
  }

  .improve_list {
    display: flex;
    flex-wrap: wrap;

    &_item {
      position: relative;
      padding: 20px;
      width: calc(100% / 5);
      text-align: center;

      border-right: 1px solid transparent;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        border-image: linear-gradient(
          -180deg,
          rgba(40, 40, 40, 0) 10%,
          rgba(40, 40, 40, 0.1) 50%,
          rgba(40, 40, 40, 0.2) 100%
        );
        border-image-slice: 1;

        &::before {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background: rgba(40, 40, 40, 0.2);
        }
      }

      &:nth-child(1) {
        &::before {
          background: linear-gradient(
            90deg,
            rgba(40, 40, 40, 0) 0%,
            rgba(40, 40, 40, 0.1) 90.2%,
            rgba(40, 40, 40, 0.2) 100%
          );
        }
      }
      &:nth-child(5) {
        &::before {
          background: linear-gradient(
            90deg,
            rgba(40, 40, 40, 0.2) 0%,
            rgba(40, 40, 40, 0.1) 10.2%,
            rgba(40, 40, 40, 0) 100%
          );
        }
      }

      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(9),
      &:nth-child(10) {
        border-image: linear-gradient(
          180deg,
          rgba(40, 40, 40, 0.2) 10%,
          rgba(40, 40, 40, 0.1) 50%,
          rgba(40, 40, 40, 0) 100%
        );

        border-image-slice: 1;
      }

      &:nth-child(5),
      &:nth-child(10) {
        border-right: none;
      }

      .improve_list_image_wrapper {
        display: flex;
        align-items: center;

        height: 120px;
      }

      & img {
        margin: 0 auto;
      }

      &_desc_wrapper {
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        // height: 100%;
      }

      &_title {
        margin-bottom: 15px;
        color: #303030;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;

        &_description {
          font-size: 15px;
          font-weight: 250;
          line-height: 20px;
        }
      }
    }
  }
}

@include for-size(desktop) {
  .section_improve {
    padding: 90px 0 0 0;
  }

  .improve_box {
    padding: 150px 40px 120px 32px;
  }
}

@include for-size(onlyDesktop) {
  .improve_box {
    padding: 150px 0 115px 118px;
  }
}
