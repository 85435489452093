.slider-container {
  position: relative; /* Установите позицию контейнера */
  margin-bottom: 50px;
}

.my-slider {
  text-align: center;

  min-height: 231px;
}

.tns-item:not(:last-child) {
  padding-right: 10px;
}

.tns-outer {
  padding-left: 30px !important;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.tns-nav {
  position: absolute;
  width: 100%;

  padding: 0 30px;
  bottom: -40px;
  left: 0;
}

.tns-nav button {
  margin: 0 !important;
  height: 3px !important;
  // width: calc(100% / 10);
  height: 10px;
  border: none;
}

.slider-container.slider-container-improve .tns-nav button {
  width: calc(100% / 10);
}

.slider-container.slider-container-trends .tns-nav button {
  width: calc(100% / 5);
}

.tns-nav-active {
  border-radius: 60px;
  background: #f1f1f1;
  background: var(
    --Linear,
    linear-gradient(116deg, #cfd1ff 51.78%, #989bff 84.71%, #777bff 109.74%)
  );
}

@include for-size(tablet) {
  .slider-container {
    display: none;
  }
}
