.modal {
  display: none;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(44, 44, 44, 0.69);
  padding: 20px;

  overflow: hidden;

  z-index: 2;
}

.modal_content {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 15px 20px 43px;
  background: $primary-color;
  border-radius: 30px;

  transform: translate(-50%, -50%);
}

.connect_us_form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button_form_cose {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: transparent;
  margin: 0 0 2px auto;
}

.connect_title {
  margin-bottom: 20px;
  color: #303030;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
}

.input_connect {
  padding-left: 15px;
  width: 338px;
  height: 65px;

  border-radius: 10px;
  border: 1.5px solid var(--Linear, #f3f3f3);

  background: #f6f6f6;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:nth-child(4) {
    padding-top: 30px;
    text-align: start;
    height: 200px;
    margin-bottom: 39px;
  }
}

.loader {
  display: none;
  position: relative;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include for-size(tablet) {
  .button_form_cose {
    position: relative;
    top: -30px;
    right: -20px;
  }
  .input_connect {
    width: 500px;
  }

  .modal_content {
    padding: 60px 40px 40px;
  }

  .connect_title {
    margin-bottom: 40px;
    font-size: 25px;
    line-height: 30px;
  }
}
