.sectopn_goals {
  padding: 50px 0 40px;

  @include for-size(tablet) {
    display: none;
  }
}

.container_goals {
  padding: 0 53px;
}

.list_goals {
  display: flex;
  flex-wrap: wrap;

  margin-top: -50px;
  margin-left: -50px;

  &_item {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 50px;

    width: calc(50% - 50px);

    text-align: left;

    font-size: 12px;

    font-weight: 300;
    line-height: 15px;
  }
}

.image_box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 11px;
  height: 80px;

  &_sub_1 {
    position: absolute;
    top: 5px;
    left: -5px;
  }

  &_sub_2 {
    position: absolute;
    top: 20px;
    right: -10px;
  }

  &_sub_3 {
    position: absolute;
    bottom: 2px;
    left: -10px;
  }

  &_sub_4 {
    position: absolute;
    top: 5px;
    right: -4px;
  }
}

.goals_description {
  color: #323232;

  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;

  &_boold {
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
  }
}
