.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  width: 100%;
  max-width: 240px;
  height: 68px;

  font-size: 16.118px;

  font-weight: 400;
  line-height: 32.235px;

  color: $primary-color;

  border: none;
  border-bottom: 1px solid #0b0f99;
  border-radius: 8.06px;
  background: var(
    --Linear,
    linear-gradient(
      180deg,
      #a4a7ff 0%,
      #7175ff 27.94%,
      #484dfd 62.42%,
      #383de7 108.78%
    )
  );

  cursor: pointer;

  transition: 300ms;
}

@include for-size(tablet) {
  .button {
    &:hover {
      filter: drop-shadow(0px 20px 50px rgba(138, 142, 255, 0.4));
    }

    &:focus {
      filter: drop-shadow(0px 20px 50px rgba(138, 142, 255, 0.4));
    }
  }

  .hero .button {
    width: 300px;
  }
}

@include for-size(desktop) {
  .hero .button {
    width: 340px;
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
  }
}
