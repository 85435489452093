.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  padding: 17px 30px;

  background-color: #202020;

  .mobile_menu {
    display: block;

    @include for-size(tablet) {
      display: none;
    }

    .burger_button {
      background: transparent;
      border: none;
    }
  }

  .logo {
    width: 160px;
    height: 47px;
    display: block;

    @include for-size(tablet) {
      display: flex;
      align-items: center;
    }
  }

  .nav {
    display: none;

    @include for-size(tablet) {
      display: block;
    }

    .list_nav {
      @include for-size(tablet) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &_item {
        @include for-size(tablet) {
          margin-right: 50px;

          color: #fff;
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
        }

        &_link {
          color: #fff;
        }

        &_link.active {
          position: relative;
          font-weight: 500;
        }

        &_link.active::before {
          content: '';
          position: absolute;
          right: 0;
          bottom: -28px;
          width: 100%;
          height: 3px;
          background: linear-gradient(
            91deg,
            #a4a7ff -4.91%,
            #7175ff 20.33%,
            #484dfd 51.47%,
            #383de7 93.35%
          );
        }
      }
    }
  }

  .language_switcher {
  }

  .mail_link_desktop {
    display: none;
  }
}

@include for-size(desktop) {
  .header {
    padding: 17px 140px;

    .logo {
      width: 218px;
      height: 65px;
    }

    .mail_link_desktop {
      display: inline-block;
      color: $primary-color;
      font-size: 20px;
      font-weight: 300;
      line-height: normal;
    }
  }
}
