.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 30px;
  padding-left: 30px;

  // @include for-size(tablet) {
  //   padding: 0 100px;
  // }

  @include for-size(desktop) {
    padding: 0 140px;
  }

  @include for-size(onlyDesktop) {
    padding: 0 200px;
  }
}
