.section_services {
  padding: 71px 0 40px 0;

  background: url('../images/our-services/bg-mask.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.title_services {
  margin-left: 10px;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36.484px;

  &_shadow {
    position: relative;
    top: 15px;
    width: 368px;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 39.25px;
    text-transform: uppercase;

    background: linear-gradient(
      91deg,
      rgba(48, 48, 48, 0.05) -0.29%,
      rgba(48, 48, 48, 0) 85.31%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.description_services {
  margin-bottom: 40px;

  color: #6b6b6b;

  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;

  &_boold {
    color: #303030;

    font-weight: 500;
  }
}

.list_services_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  margin-bottom: 40px;
}

.services_image_box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  width: 82px;
  height: 82px;
  background: #f3f3f3;
  border-radius: 5px;
}

.list_services_item:nth-child(4) .services_image_box {
  padding-right: 9px;
}

.list_services_item:nth-child(5) .services_image_box {
  padding-left: 9px;
}

.services_image_sub_1 {
  position: absolute;
  bottom: -9px;
  left: 27px;
}

.services_image_sub_2 {
  position: absolute;
  bottom: 3px;
  left: 11px;
}

.services_image_sub_3 {
  position: absolute;
  top: 1px;
  right: 3px;
}

.services_image_sub_4 {
  position: absolute;
  top: 23px;
  right: 3px;
}

.services_image_sub_5 {
  position: absolute;
  bottom: 5px;
  left: 0;
}

.services_item_title {
  margin-bottom: 13px;
  text-align: center;
  color: #303030;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.services_item_description {
  padding: 0 10px;
  color: #6b6b6b;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 250;
  line-height: 22px;
}

.button.button_services {
  justify-content: center;
  margin: 0 auto;
}

@include for-size(tablet) {
  .section_services {
    padding: 111px 0 40px 0;

    background: url('../images/our-services/bg-mask-desktop.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }
}

@include for-size(desktop) {
  .title_services {
    font-size: 50px;
    line-height: 70px;

    &_shadow {
      top: 35px;
      width: 100%;
      font-size: 130px;
      line-height: 104px;
      background: linear-gradient(
        91deg,
        rgba(48, 48, 48, 0.05) -0.29%,
        rgba(48, 48, 48, 0) 85.31%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .description_services {
    margin-bottom: 80px;
    max-width: 756px;
    font-size: 20px;
    line-height: 30px;
  }

  .list_services {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
    height: 212px;

    &_item {
      width: calc(100% / 5);
      height: 100%;
      margin: 0;
    }
  }
}
