.hero {
  position: relative;
  background-image: url('../images/hero/bg-mobile.webp');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 100vh;
  padding-top: 80px;

  &::before {
    display: none;
  }
}

.hero .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 30px;
}

.hero_wrapper {
  display: flex;
  align-items: center;

  .hero_social_list {
    display: none;
  }
}

.hero_title {
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 500;
  line-height: 30px;
}

.hero_descrption {
  margin-bottom: 40px;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;

  &_boold {
    font-weight: 500;
  }
}

.video_box {
  display: flex;
  align-items: center;
  padding-top: 32px;

  &_description {
    color: #323232;

    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;

    &_boold {
      font-weight: 700;
    }
  }

  .video_btn {
    background: transparent;

    border: none;
    cursor: pointer;
  }

  .video_btn_icon {
    margin-right: 15px;
  }
}

.hero_list_goals {
  display: none;
}

.btn_bottom_bpx {
  display: flex;
  justify-content: center;

  .bottom_btn {
    position: relative;
    top: -73px;
    border: none;
    border-radius: 50%;
    background: transparent;
  }
}

@include for-size(tablet) {
  .hero {
    background-image: url('../images/hero/hero-mask.webp'),
      url('../images/hero/bg-desktop.webp');

    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 96px;
      height: 100vh;
      width: 1px;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.01) 10%,
        rgba(0, 0, 0, 0.03) 30%,
        rgba(0, 0, 0, 0.138) 50%,
        rgba(0, 0, 0, 0.03) 80%,
        rgba(0, 0, 0, 0.01) 100%
      );
    }
  }

  .hero_wrapper .hero_social_list {
    display: block;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 30px;
    height: 100%;

    &_item:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .hero_content {
    padding-left: 50px;
  }

  .hero_title {
    font-size: 35px;
    line-height: 40px;
  }

  .hero_descrption {
    max-width: 533px;
    margin-bottom: 50px;

    &_boold {
      font-weight: 500;
    }
  }

  .hero_buttons_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 33px;
  }

  .video_box {
    margin: 0 0 0 20px;
    padding: 0;
  }

  .hero_list_goals {
    display: flex;
    align-items: center;
    width: 600px;

    &_item {
      display: flex;
      align-items: center;
      flex-direction: column;

      text-align: left;

      font-size: 12px;

      font-weight: 300;
      line-height: 15px;

      &:not(:last-child) {
        margin-right: 5opx;
      }
    }
  }
}

@include for-size(desktop) {
  .hero_content {
    padding-left: 44px;
  }

  .hero_title {
    font-size: 60px;
    line-height: 70px;
  }

  .hero_descrption {
    max-width: 733px;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
  }
}
